<template>
    <v-dialog
        class="overflow-visible"
        no-click-animation
        max-width="400"
        persistent
        v-model="dialog"
    >
        <v-card >
            <v-card-title>
                <v-icon left>mdi-alert</v-icon>
                <span class="font-weight-bold">Please Confirm</span>
            </v-card-title>
            <v-card-text >
                Are you sure you want to remove this plan from the marketplace?
                <div class="mt-5 d-flex" style="gap: 1rem">
                    <v-btn :loading="status.deletingPlan" small color="error" @click="removeFromMarketplace()">
                        Remove
                    </v-btn>
                    <v-btn small color="secondary" @click="closeDialog()">
                        No
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'RemoveFromMarketplace',
    data() {
        return {
            dialog: false
        }
    },
    props: {
        plan: Object
    },
    computed: {
        ...mapState({
            status: state => state.marketplace.status,
            role: state => state.user.user.role,
        })
    },
    methods: {
        ...mapActions('marketplace', [
            'removePlan'
        ]),
        closeDialog() {
            this.dialog = false
        },
        showDialog() {
            this.dialog = true
        },
        removeFromMarketplace() {
            const task = Promise.resolve(this.removePlan({ mealPlan: this.plan.id, role: this.role }))

            task.then(({ error }) => {
                if (!error) {
                    this.closeDialog()
                }
            })
        }
    }
}
</script>

<style>

</style>